import { getObjectValue } from '@utility/utills';
import { APIConfig } from '@constants';

const HOSTNAME = APIConfig.hostname;

const PUSH_THEME_STYLES = 'theme/PUSH_THEME_STYLES';
const GET_THEME_STYLES = 'theme/PUSH_THEME_STYLES';

const GET_BRAND_THEME_URL_REQUEST = 'theme/GET_BRAND_THEME_URL_REQUEST';
const GET_BRAND_THEME_URL_SUCCESS = 'theme/GET_BRAND_THEME_URL_SUCCESS';
const GET_BRAND_THEME_URL_FAILURE = 'theme/GET_BRAND_THEME_URL_FAILURE';

const GET_BRAND_CUSTOMIZATION_REQUEST = 'theme/GET_BRAND_CUSTOMIZATION_REQUEST';
const GET_BRAND_CUSTOMIZATION_SUCCESS = 'theme/GET_BRAND_CUSTOMIZATION_SUCCESS';
const GET_BRAND_CUSTOMIZATION_FAILURE = 'theme/GET_BRAND_CUSTOMIZATION_FAILURE';

const GET_LANGUAGE_THEME_URL_REQUEST = 'theme/GET_LANGUAGE_THEME_URL_REQUEST';
const GET_LANGUAGE_THEME_URL_SUCCESS = 'theme/GET_LANGUAGE_THEME_URL_SUCCESS';
const GET_LANGUAGE_THEME_URL_FAILURE = 'theme/GET_LANGUAGE_THEME_URL_FAILURE';

const REMOVE_THEME = 'theme/REMOVE_THEME';

const initalValues = {
  loading: false,
  loaded: true,
  storeThemeUrl: '',
  styles: {},
  themeProperties: JSON.parse(window.sessionStorage.getItem('themeProperties')) || {},
  variableProperties: {}
};

const removeTheme = () => {
  try {
    const prevStyle = document.getElementById('themingStyleSheet');
    if (prevStyle) {
      prevStyle.parentNode.removeChild(prevStyle);
    }
    const prevHeaderScriptUrl = document.getElementById('headerScriptUrl');
    if (prevHeaderScriptUrl) {
      prevHeaderScriptUrl.parentNode.removeChild(prevHeaderScriptUrl);
    }
    // const prevPaymentCompletionScriptUrl = document.getElementById('paymentCompletionScriptUrl');
    // if (prevPaymentCompletionScriptUrl) {
    //   prevPaymentCompletionScriptUrl.parentNode.removeChild(prevPaymentCompletionScriptUrl);
    // }
  } catch (e) {
    console.log(e);
  }
};


const updateTheme = (res) => {
  removeTheme();

  /* eslint-disable */
  const popIn = JSON.parse(window.sessionStorage.getItem('popIn')) || null;
  const brandId = getObjectValue(() => popIn.store.brandId, 0)
  if (popIn && (brandId === 10000003) && getObjectValue(() => popIn.flowType, false) !== 2 && HOSTNAME.indexOf('.dev.') === -1) {
    document.getElementById('facebookPixcel').innerText = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('dataProcessingOptions', ['LDU'], 0, 0);fbq('init', '1222348444600337');fbq('track', 'PageView');`;
  } else if (document.getElementById('facebookPixcel')) {
    document.getElementById('facebookPixcel').innerText = ``;
  }
  /* enable-disable */

  /* eslint-disable */
  if (popIn && getObjectValue(() => popIn.store.id, 0) === 10000092) {
    document.getElementById('gTag2').innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MD768K6');`;
  } else if (document.getElementById('gTag2')) {
    document.getElementById('gTag2').innerText = ``;
  }

  // remove googleBodyTag (if any )
  try {
    document.getElementById('facebookTagImg2').innerHTML = '';
    document.getElementById('facebookPixcel').innerText = '';
    if (document.getElementById('googleBodyTag')) {
      const element = document.getElementById("googleBodyTag");
      element.parentNode.removeChild(element);
    }
    if (document.getElementById('siftFraud')) {
      const element = document.getElementById("siftFraud");
      element.parentNode.removeChild(element);
    }
    if (document.getElementById('userway')) {
      const element = document.getElementById("userway");
      element.parentNode.removeChild(element);
    }
    if (document.getElementById('headerJs')) {
      const element = document.getElementById("headerJs");
      element.parentNode.removeChild(element);
    }
    if (document.getElementById('facebookPixcelNonse')) {
      const element = document.getElementById("facebookPixcelNonse");
      element.parentNode.removeChild(element);
    }
  } catch (e) {
    console.log(e);
  }
  if (popIn && (brandId === 10000087)) {
    let gT = document.createElement('script');
    gT.src = 'https://www.googletagmanager.com/gtag/js?id=AW-954642213';
    gT.id = "googleBodyTag";
    gT.async = true;
    document.getElementsByTagName('body')[0].appendChild(gT);
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-954642213');

    document.getElementById('facebookTagImg2').innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=817376102452997&ev=PageView&noscript=1" />`;
    document.getElementById('facebookPixcel').innerText = `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '817376102452997');
    fbq('track', 'PageView');`;
  }

  if (popIn && (brandId === 10000245)) {
    let gT = document.createElement('script');
    gT.src = 'https://www.googletagmanager.com/gtag/js?id=UA-161437660-1';
    gT.id = "googleBodyTag";
    gT.async = true;
    document.getElementsByTagName('body')[0].appendChild(gT);
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'UA-161437660-1');

    document.getElementById('facebookTagImg2').innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=576955216240958&ev=PageView&noscript=1" />`;
    document.getElementById('facebookPixcel').innerText = `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '576955216240958');
    fbq('track', 'PageView');`;
  }
  
  if (res.styleUrl) {
    const css = document.createElement('link');
    css.type = 'text/css';
    css.id = 'themingStyleSheet';
    css.href = `${res.styleUrl}?date=${new Date()}`;
    css.rel = 'stylesheet';
    document.getElementsByTagName('head')[0].appendChild(css);
  }
  /* enable-disable */
  /* eslint-disable */
  try {
    document.getElementById('headerImage').innerHTML = '';
    if (document.getElementById('headerScriptUrlExe')) {
      document.getElementById('headerScriptUrlExe').parentNode.removeChild(document.getElementsById('headerScriptUrlExe'));
    }
  } catch (e) {
    console.log(e);
  }

  /* enable-disable */
  const isUserLogin = JSON.parse(window.localStorage.getItem('isUserLogin'));
  const isIos = JSON.parse(window.localStorage.getItem('ios'));
  if (isUserLogin && getObjectValue(() => popIn.store.fraudInfo, false)) {
    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const sessionId = window.sessionStorage.getItem('sessionId');
    const siftFraud = document.createElement('script');
    const _user_id = getObjectValue(() => userData.user.id, null); // Set to the user's ID, username, or email address, or '' if not yet known.
    const _session_id = sessionId || null; // Set to a unique session ID for the visitor's current browsing session.
    var _sift = window._sift = window._sift || [];
          _sift.push(['_setAccount', getObjectValue(() => popIn.store.fraudInfo.siftBeaconKey, null)]);
          _sift.push(['_setUserId', _user_id]);
          _sift.push(['_setSessionId', _session_id]);
          _sift.push(['_trackPageview']);
    siftFraud.type = 'text/javascript';
    siftFraud.id = 'siftFraudId';
    siftFraud.src = `https://cdn.sift.com/s.js`;
    document.getElementsByTagName('body')[0].appendChild(siftFraud);
  }
  /* eslint-enable */

  if (popIn && popIn.store && popIn.store.accessibilityInfo !== null && popIn.store.accessibilityInfo.userWayAccountId !== null && !isIos) {
    const userWayScript = document.createElement('script');
    userWayScript.setAttribute('data-account', getObjectValue(() => popIn.store.accessibilityInfo.userWayAccountId, ''));
    userWayScript.setAttribute('src', 'https://cdn.userway.org/widget.js');
    userWayScript.defer = true;
    userWayScript.setAttribute('id', 'userway');
    document.getElementsByTagName('head')[0].appendChild(userWayScript);
  }

  if (res.headerScriptTag) {
    const headerJs = document.createElement('script');
    headerJs.src = res.headerScriptTag;
    headerJs.id = 'headerJs';
    document.getElementsByTagName('head')[0].appendChild(headerJs);
  }

  if (res.headerImageTag) {
    document.getElementById('headerImage').innerHTML = `<noscript><img height="1" width="1" style="border-style:none;" alt="" src=${res.headerImageTag} /></noscript>`;
  }

  if (res.headerScriptUrl) {
    const headerScriptUrl = document.createElement('script');
    headerScriptUrl.type = 'text/javascript';
    headerScriptUrl.id = 'headerScriptUrl';
    headerScriptUrl.src = `${res.headerScriptUrl}`;
    document.getElementsByTagName('head')[0].appendChild(headerScriptUrl);
  }

  // if (res.paymentCompletionScriptUrl && res.headerScriptTag) {
  //   const headerJsExe = document.createElement('script');
  //   headerJsExe.type = 'text/javascript';
  //   headerJsExe.id = 'headerScriptUrlExe';
  //   headerJsExe.src = `${res.paymentCompletionScriptUrl}`;
  //   document.getElementsByTagName('body')[0].appendChild(headerJsExe);
  // }

  if (res && getObjectValue(() => JSON.parse(res.headerMetaTag), null)) {
    const metaResponse = JSON.parse(res.headerMetaTag);
    const data = document.createElement('meta');
    data.name = getObjectValue(() => metaResponse.name, '');
    data.content = getObjectValue(() => metaResponse.content, '');
    document.getElementsByTagName('head')[0].appendChild(data);
  }

  /* eslint-disable */
};

export default (state = initalValues, action) => {
  switch (action.type) {
    case PUSH_THEME_STYLES: {
      window.sessionStorage.setItem('themeStyle', JSON.stringify(action.payload));
      return { ...state, styles: action.payload };
    }
    case GET_THEME_STYLES:
      return { ...state, styles: action.result };
    case GET_BRAND_THEME_URL_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true
      };
    case GET_BRAND_THEME_URL_SUCCESS:
      window.sessionStorage.setItem('themeProperties', JSON.stringify(action.result));
      updateTheme(action.result);
      return {
        ...state,
        loaded: true,
        loading: false,
        themeProperties: action.result,
        variableProperties: action.result && action.result.appCustomization ? JSON.parse(action.result.appCustomization || {}) : {}
      };
    case GET_BRAND_CUSTOMIZATION_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true
      };
    case GET_BRAND_CUSTOMIZATION_SUCCESS:
      window.sessionStorage.setItem('themeProperties', JSON.stringify(action.result));
      updateTheme(action.result);
      return {
        ...state,
        loaded: true,
        loading: false,
        themeProperties: action.result,
        variableProperties: action.result && action.result.appCustomization ? JSON.parse(action.result.appCustomization || {}) : {}
      };
    case GET_BRAND_CUSTOMIZATION_FAILURE:
      return {
        ...state,
        loaded: false,
        loading: false
      };
    case REMOVE_THEME:
      window.sessionStorage.removeItem('themeProperties');
      removeTheme();
      return {
        ...state,
        themeProperties: {}
      };
    case GET_BRAND_THEME_URL_FAILURE:
      return {
        ...state,
        loaded: false,
        loading: false
      };
    case GET_LANGUAGE_THEME_URL_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true
      };
    case GET_LANGUAGE_THEME_URL_SUCCESS:
      window.sessionStorage.setItem('themeProperties', JSON.stringify(action.result));
      updateTheme(action.result);
      return {
        ...state,
        loaded: true,
        loading: false,
        themeProperties: action.result,
        variableProperties: action.result && action.result.appCustomization ? JSON.parse(action.result.appCustomization) : {}
      };
    case GET_LANGUAGE_THEME_URL_FAILURE:
      return {
        ...state,
        loaded: false,
        loading: false
      };
    default:
      return state;
  }
};

export const getStoreThemeUrl = (brandId, storeId) => {
  return {
    types: [GET_BRAND_THEME_URL_REQUEST, GET_BRAND_THEME_URL_SUCCESS, GET_BRAND_THEME_URL_FAILURE],
    promise: client => client.get(`getStoreThemeUrl/${brandId}/storeId/${storeId}`)
  };
  // return (dispatch) => {
  //   dispatch({
  //     type: GET_BRAND_THEME_URL_FAILURE,
  //     payload: storeId
  //   });
  // };
};

export const appCustomization = (brandId, storeId) => {
  return {
    types: [GET_BRAND_CUSTOMIZATION_REQUEST, GET_BRAND_CUSTOMIZATION_SUCCESS, GET_BRAND_CUSTOMIZATION_FAILURE],
    promise: client => client.get(`appCustomization/${brandId}/storeId/${storeId}`)
  };
  // return (dispatch) => {
  //   dispatch({
  //     type: GET_BRAND_THEME_URL_FAILURE,
  //     payload: storeId
  //   });
  // };
};

export const getBrandThemeUrl = (brandId) => {
  return {
    types: [GET_BRAND_THEME_URL_REQUEST, GET_BRAND_THEME_URL_SUCCESS, GET_BRAND_THEME_URL_FAILURE],
    promise: client => client.get(`getBrandThemeUrl/${brandId}`)
  };
  // return (dispatch) => {
  //   dispatch({
  //     type: GET_BRAND_THEME_URL_FAILURE,
  //     payload: storeId
  //   });
  // };
};

export const appBrandCustomization = (brandId) => {
  return {
    types: [GET_BRAND_CUSTOMIZATION_REQUEST, GET_BRAND_CUSTOMIZATION_SUCCESS, GET_BRAND_CUSTOMIZATION_FAILURE],
    promise: client => client.get(`appBrandCustomization/${brandId}`)
  };
  // return (dispatch) => {
  //   dispatch({
  //     type: GET_BRAND_THEME_URL_FAILURE,
  //     payload: storeId
  //   });
  // };
};

export const removeThemeStyle = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_THEME
    });
  };
};

export const pushThemeStyles = (data) => {
  return (dispatch) => {
    dispatch({
      type: PUSH_THEME_STYLES,
      payload: data
    });
  };
};

export const getLanguageThemeUrl = (brandId, storeId, languageId) => {
  return {
    types: [GET_LANGUAGE_THEME_URL_REQUEST, GET_LANGUAGE_THEME_URL_SUCCESS, GET_LANGUAGE_THEME_URL_FAILURE],
    promise: client => client.get(`getStoreThemeUrl/${brandId}/storeId/${storeId}/languageId/${languageId}`)
  };
};
