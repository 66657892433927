import React, {
  Fragment, useState, useEffect, useRef
} from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import HeaderComponent, { SideBar, RestaurantHeader } from '@components/common/header/index';
import {
  changeMenu,
  getMenuBasedOnLanguage,
  replaceCart,
  clearMenu
} from '@reducers/orders';
import {
  // urlDataList,
  toggleNavBar, closeNavBar, toggleCancelButtonSamePage
} from '@reducers/header';
import { sessionTrack } from '@reducers/controller';
import { logout } from '@reducers/oauth';
import { APP_CONFIG } from '@constants';
import { getObjectValue } from '@utility/utills';
import { getLanguageThemeUrl, getStoreThemeUrl } from '@reducers/theme';
// import styles from '@components/common/header/header.module.scss';

document.addEventListener('userway:render_completed', () => {
  // old code for icon placement
  // const userway = document.querySelector('div.uai');
  // const imageUserWay = document.querySelector('img.ui_w');
  // userway.classList.add(styles.customIconClass);
  // imageUserWay.classList.add(styles.userWayImageIcon);
});

const Header = (props) => {
  const { popInData } = props;
  // const [isSelect, setIsSelect] = useState(false);
  // const [urlList, setUrlList] = useState([]);
  const {
    isNavOpen,
    themeStyle,
    headerBar,
    menu,
    closeButton,
    restaurantInfo,
    tempLoad,
    // menuOptions,
    menuIndex,
    menusList,
    themeProperties
  } = props;
  const isRestaurantBarOpen = restaurantInfo.show;
  const isFromReceiptPage = getObjectValue(() => props.history.location.state.isFromReceiptPage, null);
  // useEffect(() => {
  //   props.urlDataList(props.location.pathname);
  //   setUrlList(props.urlData);
  //   if (props.location.pathname === '/orders') {
  //     setIsSelect(!isSelect);
  //   } else {
  //     setIsSelect(false);
  //   }
  // }, [props.location.pathname]);
  console.log(tempLoad);

  const getSearchParameters = (url) => {
    let searchParam = null;
    const tempURL = url && url.split('?');
    if (isFromReceiptPage) {
      searchParam = `flowType=${props.popInData.flowType}&orderPaid=true&splitType=null`;
    }
    if (tempURL && tempURL.length > 0 && tempURL[0] === '/loyalty-offers') {
      searchParam = tempURL[1];
    }
    if (tempURL && tempURL.length > 0 && tempURL[0] === '/waitlist-confirmation') {
      searchParam = tempURL[1];
    }
    return searchParam;
  };

  const getPathName = (url) => {
    let result = url;
    const tempURL = url && url.split('?');
    if (tempURL && tempURL.length > 0 && tempURL[0] === '/loyalty-offers') {
      result = tempURL[0];
    }
    if (tempURL && tempURL.length > 0 && tempURL[0] === '/waitlist-confirmation') {
      result = tempURL[0];
    }
    return result;
  };
  const onNavigate = (url, isClose) => {
    const tempUrl = url.includes('?');
    if (menu.type === 1 && !isClose) {
      props.closeNavBar(false);
    }
    const selectedTip = JSON.parse(window.sessionStorage.getItem('tip'));
    window.sessionStorage.removeItem('offerCode');
    if (url !== '/tip' && selectedTip !== null) {
      window.sessionStorage.removeItem('tip');
      window.sessionStorage.removeItem('tipRoundUpEnable');
    }
    switch (url) {
      case '/logout':
        props.logout();
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
        window.location.href = '/dashboard';
        // props.history.replace(`${APP_CONFIG.BASE_URL}/dashboard`);
        break;
      case '/tip':
        if (window.location.pathname === '/gift-card') {
          props.history.replace({ pathname: `${APP_CONFIG.BASE_URL}/tip`, search: '?backTo=tip' });
        } else {
          props.history.replace(`${APP_CONFIG.BASE_URL}/tip`);
        }
        break;
      default:
        // window.localStorage.setItem('checkInRequired', false);
        if (tempUrl && getSearchParameters(url) === null) props.history.push(`${APP_CONFIG.BASE_URL}${getPathName(url)}`);
        else {
          props.history.replace({
            pathname: `${APP_CONFIG.BASE_URL}${getPathName(url)}`,
            state: props.history.location.state,
            search: getSearchParameters(url)
          });
        }
        break;
    }
  };
  const onAccountTrack = () => {
    const dataToSend = {
      activityType: 'account',
      data: {}
    };
    props.sessionTrack(dataToSend);
  };
  const closeOnSamePage = () => {
    props.toggleCancelButtonSamePage(true);
  };
  const onMenuChangeHandler = (option) => {
    props.changeMenu(option.value);
  };
  const goBack = () => {
    // if (!['/eula', '/privacyPolicy', '/termsandconditions', '/mydata', '/profile/basicInfo', '/orders-history'].includes(urlList[urlList.length - 1])) {
    //   props.history.replace(`${APP_CONFIG.BASE_URL}${urlList[urlList.length - 1]}`);
    // } else {
    //   props.history.replace(`${APP_CONFIG.BASE_URL}/dashboard`);
    // }
  };
  const redirect = (url) => {
    if (url === '/account') {
      console.log('window.sessionStorage.setItem(', `${APP_CONFIG.BASE_URL}`, window.location.search, window.location.pathname);
      window.sessionStorage.setItem('accountPageToRedirect', `${window.location.pathname}${window.location.search}`);
      props.history.push(`${APP_CONFIG.BASE_URL}${url}`);
    } else {
      props.history.replace(`${APP_CONFIG.BASE_URL}${url}`);
    }
  };
  console.log('props.isFooterOpen', props.isFooterOpen);

  const getSelectedLanguage = () => {
    const langId = window.sessionStorage.getItem('languageId') || getObjectValue(() => themeProperties.languageId, null);
    let result = getObjectValue(() => themeProperties.languageId, null);
    if (langId) {
      result = Number(langId);
    }
    return result;
  };

  const [selectedLanguageId, setSelectedLanguageId] = useState(getSelectedLanguage());
  const [prevLang, setPrevLang] = useState();
  const prevLangRef = useRef();
  useEffect(() => {
    prevLangRef.current = selectedLanguageId;
    setPrevLang(prevLangRef.current);
  }, [selectedLanguageId]);
  const handleMenuSelectionBasedOnLanguage = (languageId) => {
    const cartItems = window.sessionStorage.getItem('cart') !== 'null' ? window.sessionStorage.getItem('cart') : [];
    if (prevLang !== languageId) { // condition to prevent call menu again
      props.clearMenu().then(() => {
        props.getMenuBasedOnLanguage(getObjectValue(() => popInData.store.id, null), popInData.flowType, languageId).then(() => {
          if (cartItems && cartItems.length > 0) {
            props.replaceCart(JSON.parse(cartItems));
          }
        });
      });
    }
  };
  const handleLanguageSelection = (languageId) => {
    setSelectedLanguageId(languageId);
    if (languageId) {
      window.sessionStorage.setItem('languageId', languageId);
      props.getLanguageThemeUrl(getObjectValue(() => popInData.store.brandId, null), getObjectValue(() => popInData.store.id, null), languageId).then((res) => {
        if (res.appCustomizationUrl) {
          handleMenuSelectionBasedOnLanguage(languageId);
        } else {
          props.getLanguageThemeUrl(getObjectValue(() => popInData.store.brandId, null), getObjectValue(() => popInData.store.id, null), 1);
          handleMenuSelectionBasedOnLanguage(1);
        }
      });
    }
  };

  return (
    <div>
      <Fragment>
        {headerBar.show && (
          <HeaderComponent
            onNavigate={onNavigate}
            closeOnSamePage={closeOnSamePage}
            headerBar={headerBar}
            menu={menu}
            closeButton={closeButton}
            onToggleNavBar={() => props.toggleNavBar()}
            isNavOpen={isNavOpen}
            themeStyle={themeStyle}
            menusList={menusList}
            history={props.history}
            // isSelect={isSelect}
            onMenuChangeHandler={onMenuChangeHandler}
            menuIndex={menuIndex}
            goBack={goBack}
            popInData={popInData}
            redirect={redirect}
            isBrowse={props.isBrowse}
            isOders={props.isOders}
            isFooterOpen={props.isFooterOpen}
            sessionTrack={props.sessionTrack}
            onAccountTrack={onAccountTrack}
            cart={props.cart}
            themeProperties={props.themeProperties}
            handleLanguageSelection={handleLanguageSelection}
            selectedLanguageId={selectedLanguageId}
            setSelectedLanguageId={setSelectedLanguageId}
          />
        )}
        <SideBar
          onToggleNavBar={() => props.toggleNavBar()}
          onNavigate={onNavigate}
          isNavOpen={isNavOpen}
        />
        {isRestaurantBarOpen && <RestaurantHeader restaurantInfo={restaurantInfo} customStyle={themeStyle} />}
      </Fragment>
    </div>
  );
};

const mapStateToProps = ({
  header,
  oauth,
  theme,
  orders,
  browse,
  controller,
}) => (
  {
    userdata: oauth.userdata,
    menuOptions: orders.menuOptions,
    loading: header.loading || oauth.loading || theme.loading || orders.loading || browse.loading || controller.loading,
    menuIndex: orders.menuIndex,
    isUserLogin: oauth.isUserLogin || window.localStorage.getItem('isUserLogin'),
    isNavOpen: header.isNavOpen,
    themeStyle: theme.styles,
    headerBar: header.headerBar,
    tempLoad: header.tempLoad,
    menu: header.menu,
    closeButton: header.closeButton,
    restaurantInfo: header.restaurantInfo,
    urlData: header.urlData,
    popInData: controller.popInData || window.sessionStorage.getItem('popIn'),
    isBrowse: browse.menuItems && browse.menuItems.length > 0,
    isOders: orders.menuItems && orders.menuItems.length > 0,
    isFooterOpen: (header.footer || {}).show,
    cart: orders.cart,
    menusList: orders.menusList,
    themeProperties: theme.themeProperties || JSON.parse(window.sessionStorage.getItem('themeProperties'))
  }
);

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleNavBar,
  logout,
  toggleCancelButtonSamePage,
  closeNavBar,
  changeMenu,
  sessionTrack,
  getLanguageThemeUrl,
  getMenuBasedOnLanguage,
  replaceCart,
  clearMenu,
  getStoreThemeUrl
  // urlDataList,
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Header));
